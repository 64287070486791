
  import { defineComponent, computed } from 'vue'
  import InputNumber, { InputNumberInputEvent } from 'primevue/inputnumber' 
  import { hasError, errorMessage } from '@/utils/helpers/validation'
  import { Validation } from '@vuelidate/core'
  
  export default defineComponent({
    components: {
      InputNumber,
    },
    props: {
      label: {
        type: String,
        required: false,
      },
      modelValue: {
        type: [Number, String],
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      suffix: {
        type: String,
        default: '',
      },
      prefix: {
        type: String,
        default: '',
      },
      path: {
        type: String,
        default: '',
      },
      v$: {
        type: Object as () => Validation,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const numberValue = computed({
            get() {
            return Number(props.modelValue) || 0
            },
            set(value: number) {
            emit('update:modelValue', value)
            emit('change', value) 
            }
        })
    
        const handleInput = (event: InputNumberInputEvent) => {
            const value = event.value
            numberValue.value = typeof value === 'number' ? value : 0 
            emit('change', numberValue.value)
        }
    
      return {
        numberValue,
        hasError,
        errorMessage,
        handleInput,
      }
    },
  })
  