import { Validation } from '@vuelidate/core'

export const getFieldValidation = (v$: Validation, path: string) => {
  if (!v$ || !path) return null 

  return path.split('.').reduce((acc, part) => acc?.[part], v$) 
}

export const hasError = (v$: Validation, path: string): boolean => {
  const field = getFieldValidation(v$, path)
  return !!field && field.$dirty && field.$invalid
}

export const errorMessage = (v$: Validation, path: string, fieldName?: string): string | null => {
  const field = getFieldValidation(v$, path)

  if (!field || !field.$dirty) return null 

  const fieldLabel = fieldName || 'Field ini'

  switch (true) {
    case field.required?.$invalid:
      return `${fieldLabel} tidak boleh kosong.`
    case field.minLength?.$invalid:
      return `${fieldLabel} minimal diisi ${field.minLength.$params.min} karakter.`
    case field.maxLength?.$invalid:
      return `${fieldLabel} maksimal diisi ${field.maxLength.$params.max} karakter.`
    case field.numeric?.$invalid:
      return `${fieldLabel} harus berupa angka.`
    case field.validIndonesianPhoneNumber?.$invalid: 
      return `${fieldLabel} tidak valid. Harus diawali dengan kode operator Indonesia (812, 813, dll).`
    default:
      return null
  }
}

// Custom Validation for Indonesian Phone Number
export const validIndonesianPhoneNumber = (value: string) => {
  // List of valid prefixes for Indonesian phone numbers
  const validPrefixes = [
    // Mobile numbers
    '812', '813', '814', '815', '851', '852', '853', '855',
    '821', '822', '823', '824', '856', '857', '858', '859',
  ]

  // Check if the value is a valid phone number
  const isValidLength = value.length >= 6 && value.length <= 13 
  const startsWithValidPrefix = validPrefixes.some(prefix => value.startsWith(prefix))

  return isValidLength && startsWithValidPrefix
}