/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { IUserProfile } from '@/utils/interfaces/iUserResponse'
import { IRootState } from './model'

const networking = {
  connection: true,
  connectionType: '',
  noInternetModal: false
}

const deviceType = {
  platform: 'web',
  isIos: false,
  isAndroid: false,
  isWeb: true,
  isMobile: false,
  width: 0,
  height: 0
}

const hjpTable = {
  reloadTable: false,
  modal: {
    show: false,
    data: null,
    titleForm: ''
  },
  rightbarForm: {
    canDelete: true
  },
  dialog: {
    show: false
  }
}

const hjpDataList = {
  modalConfirmation: {
    show: false,
    title: '',
    subTitle: '',
    btnSubmitTitle: '',
    btnBackTitle: '',
    imgContent: ''
  },
  modalForm: {
    show: false,
    titleForm: '',
    data: null
  },
  reloadData: false
}

const dashboard = {
  reloadRit1: false,
  reloadRit2: false,
}

const headerButton = {
  isShow: false,
  label: '',
  route: '',
  isShowDialogForm: false
}

type Nullable<T> = T | undefined | null
const userProfile: Nullable<IUserProfile> = null

const state: IRootState = {
  network: networking,
  device: deviceType,
  loading: false,
  toggleMenu: true,
  toggleMinimizeMenu: false,
  toggleSidebar: false,
  hjpTable,
  hjpDataList,
  userProfile,
  selectedPassenger: null,
  selectedPriceChange: null,
  globalSettingHjex: null,
  togglePasswordDialog: false,
  dashboard,
  fieldSearch: null,
  headerButton,
  switchEnv: '',
}

export default state
