
import { defineComponent, computed, ref, watch } from 'vue'
import { hasError, errorMessage } from '@/utils/helpers/validation'
import { Validation } from '@vuelidate/core'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '', 
    },
    v$: {
      type: Object as () => Validation,
      default: null, 
    },
    rows: {
      type: Number,
      default: 5,
    },
    cols: {
      type: Number,
      default: 30,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autoResize: {
      type: Boolean,
      default: true, 
    },
    minLength: {
      type: Number,
      default: null, 
    },    
    maxLength: {
      type: Number,
      default: null, 
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const stringValue = computed({
      get() {
        return String(props.modelValue)
      },
      set(value: string) {
        if (props.maxLength === null || value.length <= props.maxLength) {
          emit('update:modelValue', value)
        }
      }
    })

    const textareaHeight = ref(0)
    const lineHeight = 24 
    const currentLength = computed(() => stringValue.value.length)

    const handleInput = (event: Event) => {
      const target = event.target as HTMLTextAreaElement
      if (props.autoResize) {
        target.style.height = 'auto' 
        textareaHeight.value = Math.max(target.scrollHeight, props.rows * lineHeight) 
      }
      stringValue.value = target.value
    }

    watch(stringValue, (newValue) => {
      if (props.autoResize) {
        const length = typeof newValue === 'string' ? newValue.length : String(newValue).length 
        textareaHeight.value = Math.max(length > 0 ? Math.ceil(length / props.cols) * lineHeight : 0, props.rows * lineHeight) 
      }
    })

    watch(() => props.modelValue, (newValue) => {
      if (props.autoResize) {
        const length = typeof newValue === 'string' ? newValue.length : String(newValue).length
        textareaHeight.value = Math.max(length > 0 ? Math.ceil(length / props.cols) * lineHeight : 0, props.rows * lineHeight)
      }
    })

    textareaHeight.value = props.rows * lineHeight

    return {
      stringValue,
      hasError,
      errorMessage,
      handleInput,
      textareaHeight,
      currentLength
    }
  },
})
