import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block font-medium mb-1"
}
const _hoisted_2 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_3 = {
  key: 1,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_InputNumber, {
      modelValue: _ctx.numberValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numberValue) = $event)),
      placeholder: _ctx.placeholder,
      min: 0,
      suffix: _ctx.suffix,
      prefix: _ctx.prefix,
      class: _normalizeClass(['w-full', { 'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path) }]),
      onChange: _ctx.handleInput
    }, null, 8, ["modelValue", "placeholder", "suffix", "prefix", "class", "onChange"]),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path, _ctx.label)), 1))
      : _createCommentVNode("", true)
  ]))
}