
import { defineComponent, computed } from 'vue'
import InputText from 'primevue/inputtext'
import { hasError, errorMessage } from '@/utils/helpers/validation'
import { Validation } from '@vuelidate/core'

export default defineComponent({
  components: {
    InputText,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '', 
    },
    v$: {
      type: Object as () => Validation,
      default: null, 
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const stringValue = computed({
      get() {
        return String(props.modelValue)
      },
      set(value: string) {
        emit('update:modelValue', value)
      }
    })

    return {
      stringValue,
      hasError,
      errorMessage,
    }
  },
})
