import DocumentIcon from './DocumentIcon.vue'
import ProfileIcon from './ProfileIcon.vue'
import VehicleTruckCubeIcon from './VehicleTruckCubeIcon.vue'
import ShippingRatesIcon from './ShippingRatesIcon.vue'

const icons = {
  DocumentIcon,
  ProfileIcon,
  VehicleTruckCubeIcon,
  ShippingRatesIcon,
} as const 

export type IconType = typeof icons
export default icons 