export const handleApiError = (errorResponse: any, $toast: any, customMessage?: string): void => {
    if (!errorResponse) return
  
    // Default title
    const title = errorResponse.title || errorResponse.statusText || 'An error occurred'
    
    // Default error message
    let errorMessage = customMessage || title
  
    // If there are error details
    if (errorResponse.errors) {
      const errorsArray = Object.values(errorResponse.errors).flat()
      errorMessage = `${title}: ${errorsArray.join(', ')}`
    }
    
    // If the response contains status (like 404), include it in the error message
    // if (errorResponse.status) {
    //   errorMessage = `${title} - Status: ${errorResponse.status}`
    // }
  
    // If there's any message in 'data', append it as well
    if (errorResponse.data) {
      errorMessage += ` - Details: ${errorResponse.data}`
    }
  
    // Display the toast with the generated error message
    $toast.add({
      severity: 'error',
      detail: errorMessage,
      group: 'br',
      closable: false,
      life: 3000
    })
  }