<template>
    <!-- eslint-disable max-len -->
    <svg 
      width="32" 
      height="32" 
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6799 19.7583C19.8481 19.7471 23.3922 21.7407 24.6948 26.0321C22.069 27.6994 18.9784 28.3417 15.6799 28.3333C12.3815 28.3417 9.29079 27.6994 6.66504 26.0321C7.96924 21.7361 11.5073 19.7471 15.6799 19.7583Z" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
        <path d="M21.337 9.55948C21.337 12.8141 18.8041 15.4524 15.6797 15.4524C12.5553 15.4524 10.0225 12.8141 10.0225 9.55948C10.0225 6.30488 12.5553 3.6665 15.6797 3.6665C18.8041 3.6665 21.337 6.30488 21.337 9.55948Z" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
    </svg>
  </template>
  
  <script>
    export default {
        props: ['currentFill']
    }
  </script>