/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-cycle, no-useless-catch */
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { authUseCase, userUseCase } from '@/usecase'
import apiUseCase from '@/usecase/apiUseCase'
import { iPayloadGetToken, iPayloadLogin } from '@/utils/interfaces/iLogin'
import { IPayloadOtp } from '@/utils/interfaces/iUserResponse'
import moment from 'moment'
import { ActionTree } from 'vuex'
import { boolean } from 'yup'
import { IRootState } from './model'

const actions: ActionTree<IRootState, IRootState> = {
  setToggleMenu: ({ commit }, toggle: boolean) => {
    commit('SET_TOGGLE_MENU', toggle)
  },
  initResize: ({ commit }) => {
    window.addEventListener('resize', () => {
      commit('SET_WIDTH', window.innerWidth)
      commit('SET_HEIGHT', window.innerHeight)
    })
  },
  initNetworkOn: ({ commit }) => {
    window.addEventListener('online', () => {
      commit('SET_CONNECTION', true)
    })
  },
  initNetworkOff: ({ commit }) => {
    window.addEventListener('offline', () => {
      commit('SET_CONNECTION', false)
      commit('SET_NO_INTERNET_MODAL', true)
    })
  },
  setWidth: ({ commit }) => {
    commit('SET_WIDTH', window.innerWidth)
  },
  setHeight: ({ commit }) => {
    commit('SET_HEIGHT', window.innerHeight)
  },
  destroyResize: ({ commit }) => {
    window.removeEventListener('resize', () => ({}))
  },
  setNoInternet: ({ commit }, noInternet: boolean) => {
    commit('SET_NO_INTERNET_MODAL', noInternet)

    if (noInternet) {
      commit('SET_SHOW_LOADING', false)
    }
  },
  showLoading: ({ commit }) => {
    commit('SET_SHOW_LOADING', true)
  },
  hideLoading: ({ commit }) => {
    commit('SET_SHOW_LOADING', false)
  },
  setToggleMenuSidebar: ({ commit }, toggle: boolean) => {
    commit('SET_TOGGLE_SIDEBAR', toggle)
  },
  // Components Data Table
  setShowModal: ({ commit }, showModal: boolean) => {
    commit('SET_SHOW_MODAL', showModal)
  },
  storeRowDataTable: ({ commit }, data: any) => {
    commit('STORE_ROW_DATA_TABLE', data)
  },
  flushRowDataTable: ({ commit }) => {
    commit('FLUSH_ROW_DATA_TABLE', null)
  },
  setTitleFormSidebar: ({ commit }, title: string) => {
    commit('SET_TITLE_FORM_SIDEBAR', title)
  },
  setReloadDataTable: ({ commit }) => {
    commit('SET_RELOAD_DATA_TABLE', true)
  },
  // Components Data List (DL: Data List)
  setShowModalFormDl: ({ commit }, show: boolean) => {
    commit('SET_SHOW_MODAL_FORM_DL', show)
  },
  storeRowDataListDl: ({ commit }, rowData: any) => {
    commit('STORE_ROW_DATA_LIST_DL', rowData)
  },
  setTitleFormSidebarDl: ({ commit }, title: string) => {
    commit('SET_TITLE_FORM_SIDEBAR_DL', title)
  },
  setShowModalConfirmationDl: ({ commit }, show: boolean) => {
    commit('SET_SHOW_MODAL_CONFIRMATION_DL', show)
  },
  setContentModalConfirmationDl: ({ commit }, data: any) => {
    commit('SET_CONTENT_MODAL_CONFIRMATION_DL', data)
  },
  setReloadDataList: ({ commit }) => {
    commit('SET_RELOAD_DATA_LIST', true)
  },
  flushRowDataList: ({ commit }) => {
    commit('STORE_ROW_DATA_LIST_DL', null)
  },
  // this action works to setting process all login process
  loginProcess: async ({ commit }, data: iPayloadLogin) => {
    try {
      const response = await authUseCase.sendingLoginChallenge(data.username, data.password)
      if (!response.error) {
        return response
      } throw response
    } catch (error) {
      throw error
    }
  },
  // this action works to get token
  processGetToken: async ({ commit, dispatch }, data: iPayloadGetToken) => {
    try {
      const payload = new URLSearchParams()
      payload.append('code', data.code)
      payload.append('client_id', data.client_id)
      payload.append('client_secret', data.client_secret)
      payload.append('redirect_uri', data.redirect_uri)
      payload.append('grant_type', data.grant_type)
      const response = await authUseCase.sendingAuth(payload)
      if (!response.error) {
        const date = moment()
        // const date = new Date()
        // date.setTime(date.getTime() + response.result.expires_in)
        localStorage.setItem('ut', response.result.access_token)
        localStorage.setItem('it', response.result.id_token)
        localStorage.setItem('ei', date.toString())
        await dispatch('fetchUserInfo', response.result.access_token)
        return response
      } throw response
    } catch (error) {
      throw error
    }
  },
  // Logout process
  logoutAccount: async ({ commit }) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        authUseCase.logout().then((response) => {
          console.log('response logout', response)
          localStorage.removeItem('ut')
          localStorage.removeItem('it')
          localStorage.removeItem('ei')
          localStorage.removeItem('userInfo')
          localStorage.removeItem('swicthEnv')
          commit('SET_USER_PROFILE', null)
        })
      } catch (error) {
        console.log('error logout', error)
      }
    } else {
      localStorage.removeItem('ut')
      localStorage.removeItem('it')
      localStorage.removeItem('ei')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('switchEnv')
      commit('SET_USER_PROFILE', null)
    }
  },

  fetchUserInfo: async ({ commit }, token: string | null) => {
    try {
      const { error, result } = await authUseCase.getUserInfo(token)
      if (!error) {
        const userInfo = encryptDecriptMethods.encrypt(JSON.stringify(result))
        localStorage.setItem('userInfo', userInfo)
      }
    } catch (error) {
      throw error
    }
  },

  // fetchProfile: async ({ commit }) => {
  //   try {
  //     const userInfo = JSON.parse(encryptDecriptMethods.decrypt(localStorage.getItem('userInfo')))
  //     const guid = userInfo.id
  //     const { error, result } = await authUseCase.getProfile(guid)
  //     if (!error) {
  //       commit('SET_USER_PROFILE', result)
  //     }
  //   } catch (error) {
  //     throw error
  //   }
  // },

  setSelectedPassenger: async ({ commit }, data: any) => {
    commit('SET_SELECTED_PASSENGER', data)
  },
  setSelectedPriceChange: async ({ commit }, data: any) => {
    commit('SET_SELECTED_PRICE_CHANGE', data)
  },
  fetchGlobalSettingHjex: async ({ commit }) => {
    try {
      const { error, result } = await userUseCase.getGlobalSettingHjex()
      if (!error) {
        commit('SET_GLOBAL_SETTING_HJEX', result)
      }
    } catch (error) {
      throw error
    }
  },

  getTokenOtp: async ({ commit }) => {
    try {
      const response = await userUseCase.loginClientCredentialsHjex()
      if (response.status === 200) {
        return response
      }
      throw response
    } catch (error) {
      return error
      throw error
    }
  },

  requestOtp: async ({ commit }, data: any) => {
    try {
      const response = await userUseCase.requestOtp(data)
      if (response.status === 200) {
        return response
      }
      throw response
    } catch (error) {
      return error
      throw error
    }
  },

  validateOtp: async ({ commit }, data: IPayloadOtp) => {
    try {
      const response = await userUseCase.validateOtp(data)
      if (response.status === 200 || response.status === 400) {
        return response
      }
      console.log('resValidate', response)
      throw response
    } catch (error) {
      throw error
    }
  },

  sendWhatsapp: async ({ commit }, data: IPayloadOtp) => {
    try {
      const response = await userUseCase.sendWhatsapp(data)
      if (response.status === 200) {
        return response
      }
      throw response
    } catch (error) {
      throw error
    }
  },

  setToogleChangePasswordDialog: async ({ commit }, data: any) => {
    commit('SET_TOGGLE_CHANGE_PASSWORD_DIALOG', data)
  },

  setReloadRit1Dashboard: async ({ commit }) => {
    commit('SET_RELOAD_RIT_1_DASHBOARD', true)
  },
  setReloadRit2Dashboard: async ({ commit }) => {
    commit('SET_RELOAD_RIT_2_DASHBOARD', true)
  },
  setReloadTrayek: async ({ commit }: any) => {
    commit('SET_RELOAD_TRAYEK', true)
  },
  setToggleMinimizeMenu: ({ commit }, toggle: boolean) => {
    commit('SET_TOGGLE_MINIMIZE_MENU', toggle)
  },
  setToggleDatatableSidebarDeleteButton: ({ commit }, toggle: boolean) => {
    commit('SET_TOGGLE_DATATABLE_SIDEBAR_DELETE_BUTTON', toggle)
  },
  setSearchField: ({ commit }, toggle: boolean) => {
    commit('SET_SEARCH_FIELD', toggle)
  },
  setTrayekHeadSearchFilter: async ({ commit }, data: any) => {
    commit('SET_TRAYEK_HEAD_SEARCH_FILTER', data)
  },
  setTrayekHeadClassFilter: async ({ commit }, data: any) => {
    console.log('data', data)
    commit('SET_TRAYEK_HEAD_CLASS_FILTER', data)
  },
  setTrayekHeadIsTolFilter: async ({ commit }, data: any) => {
    commit('SET_TRAYEK_HEAD_ISTOL_FILTER', data)
  },
  flushTrayekHeadFilter: async ({ commit }) => {
    commit('FLUSH_TRAYEK_HEAD_FILTER', null)
  },
  setTrayekForm1: async ({ commit }, data: any) => {
    console.log('data', data)
    commit('SET_TRAYEK_FORM1', data)
  },
  flushTrayekForm1: async ({ commit }) => {
    commit('FLUSH_TRAYEK_FORM1')
  },
  setTrayekForm2: async ({ commit }, data: any) => {
    commit('SET_TRAYEK_FORM2', data)
  },
  setTrayekFormStep: async ({ commit }, data: any) => {
    commit('SET_TRAYEK_FORM_STEP', data)
  },
  setTrayekPath: async ({ commit }, data: any) => {
    commit('SET_TRAYEK_PATH', data)
  },
  setHeaderButton: async ({ commit }, data: any) => {
    commit('SET_HEADER_BUTTON', data)
  },
  setHeaderButtonActionShowDialogForm: async ({ commit }, data: any) => {
    commit('SET_HEADER_BUTTON_ACTION_SHOW_DIALOG_FORM', data)
  },
  flushHeaderButton: async ({ commit }) => {
    commit('FLUSH_HEADER_BUTTON')
  },
  setListBusTrayek: async ({ commit }, data: any) => {
    commit('SET_LISTBUS_TRAYEK', data)
  },
  setVehicleMutationFormStep: async ({ commit }: any, data: any) => {
    console.log('data', data)
    commit('SET_VEHICLE_MUTATION_FORM_STEP', data)
  },
  setFirstVehicleMutation: async ({ commit }: any, data: any) => {
    commit('SET_FIRST_VEHICLE_MUTATION', data)
  },
  setSecondVehicleMutation: async ({ commit }: any, data: any) => {
    commit('SET_SECOND_VEHICLE_MUTATION', data)
  },
  setDescriptionVehicleMutation: async ({ commit }: any, data: any) => {
    commit('SET_DESCRIPTION_VEHICLE_MUTATION', data)
  },
  flushVehicleMutationForm: async ({ commit }: any) => {
    commit('FLUSH_VEHICLE_MUTATION_FORM')
  },
  setShowDialog: ({ commit }: any, showModal: boolean) => {
    commit('SET_SHOW_DIALOG', showModal)
  },
  setEnvironment: ({ commit }: any, data: string) => {
    commit('SET_ENVIRONTMENT', data)
  },
}

export default actions
