
  import './hjp-confirmation-modal.scss'
  import Dialog from 'primevue/dialog'
  import {
    defineComponent,
    computed,
    reactive
  } from 'vue'
  import store from '@/store'

  export default defineComponent({
    name: 'ModalDefault',
    components: {
      Dialog,
    },
    props: {
      show: {
        type: Boolean,
        default: () => true
      },
      width: {
        default: () => '50vw'
      }
    },
    emits: ['hideDialog'],
    setup(props, { emit }: any) {
      const propertiesProps = reactive(props)
      const showModal = computed({
        get: () => propertiesProps.show,
        set: (val) => {
          if (!val) {
            emit('hideDialog')
          }
        }
      })
      const closeDialog = () => {
        store.dispatch('flushVehicleMutationForm')
        showModal.value = false
      }
      return {
        showModal,
        closeDialog
      }
    }
  })
