/* eslint-disable no-confusing-arrow */
import moment, { unitOfTime } from 'moment'

/**
 * Converts a UTC date string to local time and formats it according to the provided format.
 * @param {string | null | undefined} date - The date string in UTC to be converted.
 * @param {string} format - The format string for the output.
 * @returns {string} - The formatted local date string.
 */
export const formatDate = (date: string | null | undefined, format: string): string => date ? moment.utc(date).format(format) : ''
// export const formatDate = (date: string | null | undefined, format: string): string => date ? moment.utc(date).local().format(format) : ''

/**
 * Calculates the difference between two dates in a specified unit.
 * @param {Date | null} firstDate - The start date or `null` if comparing with the current date.
 * @param {Date} secondDate - The end date for comparison.
 * @param {unitOfTime.Base} key - The unit for difference calculation (e.g., 'days', 'hours').
 * @returns {number} - The difference between the dates in the specified unit.
 */
export const differenceDate = (firstDate: Date | null, secondDate: Date, key: unitOfTime.Base): number => firstDate ? moment(firstDate).diff(secondDate, key) : moment().diff(secondDate, key)

/**
 * Calculates the time difference between two dates in UTC format.
 * @param {Date} firstDate - The start date.
 * @param {Date} secondDate - The end date.
 * @returns {string} - The time difference formatted in HH:mm:ss format.
 */
export const differenceTime = (firstDate: Date, secondDate: Date): string => moment.utc(moment(firstDate).diff(moment(secondDate))).format('HH:mm:ss')