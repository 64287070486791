/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import Repository from '@/data/repository'
import apiHjex from '@/configs/api_hjex'
import {
  Remote,
  remoteEnum
} from '@/data/remote/remote'
import {
  Remote as RemoteHjex,
} from '@/data/remote/remoteHjex'
import { LocalDb, localDbEnum, LocalDbEntity } from '@/data/local/databases/localDb'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

const endpoint = 'login'
const oauth2Endpoint = 'oauth2'
class AuthUseCase {
  sendingLoginChallenge(username: string, password: string): Promise<any> {
    const payload = {
      email: username,
      password: password,
      // remember: false
    }
    return new Repository(new Remote(remoteEnum.post, `openapi/v1/identity/login`, payload), null).getResult(false)
    // return api.post(`management/${endpoint}?login_challenge=${valLoginChallenge}`, data)
  }

  sendingHjexLoginChallenge(valLoginChallenge: any, username: string, password: string): Promise<any> {
    const data = {
      username: username,
      password: password,
      remember: false
    }
    return apiHjex.post(`login?login_challenge=${valLoginChallenge}`, data)
  }

  sendingAuth(data: any) {
    return new Repository((new Remote(remoteEnum.post, `${oauth2Endpoint}/token`, data, {
      'Content-Type': 'application/x-www-form-urlencoded'
    })), null).getResult(false)
  }

  getUserInfo(token: string | null) {
    // console.log('token masuk', token)
    const header = {
      'Authorization': `Bearer ${token}`
    }
    return new Repository((new Remote(remoteEnum.get, 'userinfo')), null).getResult(false)
  }

  getProfile(guid: string) {
    // const token = localStorage.getItem('ut')
    return new Repository((new Remote(remoteEnum.get, `management/v1/User/${guid}`)), null).getResult(false)
  }

  logout() {
    return new Repository((new Remote(remoteEnum.post, 'management/Backchannel', {
      logout_token: localStorage.getItem('it')
    })), null).getResult(false)
  }
}

const authUseCase = new AuthUseCase()

export {
  authUseCase
}
