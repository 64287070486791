import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full search-field-adv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconField, { iconPosition: "right" }, {
      default: _withCtx(() => [
        _createVNode(_component_InputIcon, {
          class: "pi pi-search cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.canClick && $setup.onClick()))
        }),
        _createVNode(_component_InputText, {
          type: "text",
          id: "input-search",
          class: "w-full py-3",
          style: _normalizeStyle($setup.inputBackground?{backgroundColor: $setup.inputBackground }:{}),
          modelValue: $setup.input,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.input) = $event)),
          placeholder: $setup.placeholder,
          autofocus: $setup.autofocus,
          disabled: $setup.disabled
        }, null, 8, ["style", "modelValue", "placeholder", "autofocus", "disabled"])
      ]),
      _: 1
    })
  ]))
}