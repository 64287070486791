
import './hjp-confirmation-modal.scss'
import Dialog from 'primevue/dialog'
import ButtonPrimary from '@/views/components/button/ButtonPrimary.vue'
import ButtonSecondary from '@/views/components/button/ButtonSecondary.vue'
import {
  defineComponent,
  computed,
  reactive,
  ref,
  useSlots
} from 'vue'

export default defineComponent({
  name: 'HjpCofirmModal',
  components: {
    Dialog,
    ButtonPrimary,
    ButtonSecondary
  },
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    isLoadingSubmit: {
      type: Boolean,
      default: () => true
    },
    imgContent: {
      default: () => require('@/assets/img/modal-confirmation/delete-default.svg')
    },
    title: {
      type: String,
      default: () => 'Title'
    },
    subTitle: {
      type: String,
      default: () => 'Sub - Tile'
    },
    subTitleHtml: {
      type: Boolean,
      default: () => true
    },
    btnSubmitTitle: {
      type: String,
      default: () => 'Simpan Perubahan'
    },
    btnBackTitle: {
      type: String,
      default: () => 'Kembali'
    }
  },
  emits: ['hideDialog', 'onSubmit'],
  setup(props, { emit }) {
    const slot = useSlots()
    const propertiesProps = reactive(props)
    const showModal = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const closeDialog = () => {
      showModal.value = false
    }
    const onSubmit = () => {
      emit('onSubmit')
    }
    const openModal = () => {
      showModal.value = true
    }
    return {
      showModal,
      closeDialog,
      onSubmit,
      openModal,
      slot
    }
  }
})
