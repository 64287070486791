import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b38374f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "block font-medium mb-1"
}
const _hoisted_2 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_3 = ["placeholder", "rows", "cols", "maxlength"]
const _hoisted_4 = {
  key: 1,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stringValue) = $event)),
      placeholder: _ctx.placeholder,
      rows: _ctx.rows,
      cols: _ctx.cols,
      class: _normalizeClass([
        'w-full border rounded-md p-2 transition-all duration-200', 
        { 
          'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path), 
          'border-green-500': _ctx.currentLength >= _ctx.minLength && _ctx.currentLength < _ctx.maxLength, 
          'border-yellow-500': _ctx.currentLength === _ctx.maxLength 
        }
      ]),
      style: _normalizeStyle({ height: _ctx.autoResize ? `${_ctx.textareaHeight}px` : 'auto', overflow: 'hidden' }),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      maxlength: _ctx.maxLength || undefined
    }, null, 46, _hoisted_3), [
      [_vModelText, _ctx.stringValue]
    ]),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path, _ctx.label)), 1))
      : _createCommentVNode("", true),
    (_ctx.maxLength && _ctx.currentLength > 0)
      ? (_openBlock(), _createElementBlock("small", {
          key: 2,
          class: _normalizeClass(["text-xs", _ctx.currentLength === _ctx.maxLength ? 'text-yellow-500' : _ctx.currentLength >= _ctx.minLength && _ctx.currentLength < _ctx.maxLength ? 'text-green-500' : ''])
        }, _toDisplayString(_ctx.currentLength) + " / " + _toDisplayString(_ctx.maxLength), 3))
      : _createCommentVNode("", true)
  ]))
}