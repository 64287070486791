
    import { defineComponent, computed } from 'vue'
    import environment from '@/configs/environtment'
    
    export default defineComponent({
        name: 'VerticalHeading',
        props: {
        title: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: 'h4',
        },
        },
        setup() {
            const isProduction = computed(() => environment.getMode() === 'production')
        
            const headingClass = computed(() => (isProduction.value ? 'primary' : 'secondary'))

            return {
                headingClass,
            }
        },
    })
  