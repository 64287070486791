
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import Password from 'primevue/password'
import Button from 'primevue/button'
import { Form, Field } from 'vee-validate'
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import * as Yup from 'yup'
import { useStore } from 'vuex'
import apiUseCase from '@/usecase/apiUseCase'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default defineComponent({
  name: 'ChangePasswordModal',
  components: {
    ModalDefault,
    Password,
    Form,
    Field,
    Button,
  },
  setup() {
    const endpoint = 'management/v1/Password/Change'
    const store = useStore()
    // const show = reactive(store.state?.togglePasswordDialog)
    const showDialog = computed({
      get: () => store.state.togglePasswordDialog,
      set: (val) => {
        store.dispatch('setToogleChangePasswordDialog', val)
      }
    })
    const hideDialog = () => {
      showDialog.value = false
    }
    const userId = ref(null)
    const initialValues = ref({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    })
    const dataForm = ref({})
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties

    const schemaValidation = Yup.object().shape({
      oldPassword: Yup.string()
        .min(8, 'Password minimal 8 karakter')
        .max(100, 'Password maksimal 100 karakter'),
      newPassword: Yup.string()
        .min(8, 'Password minimal 8 karakter')
        .max(100, 'Password maksimal 100 karakter')
        .notOneOf([Yup.ref('oldPassword')], 'Password Baru tidak boleh sama dengan Password Lama'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Password tidak sama')
    })

    const onSubmit = async (values: any) => {
      // console.log('submit', [userId.value.id, values])
      dataForm.value = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      }
      // const response = await apiUseCase.postOrPut(endpoint, userId.value.id, dataForm.value)
      // console.log('response submit', response)
      // if (!response.error) {
      //   store.dispatch('setToogleChangePasswordDialog', false)
      //   $toast.add({
      //     severity: 'success',
      //     // summary: response.result.Header,
      //     detail: 'Password berhasil diubah',
      //     group: 'bc',
      //     closable: false,
      //     life: 3000
      //   })
      // } else {
      //   // store.dispatch('setShowModal', false)
      //   $toast.add({
      //     severity: 'error',
      //     // summary: response.result.Header,
      //     detail: response.message,
      //     group: 'bc',
      //     closable: false,
      //     life: 3000
      //   })
      // }
    }

    onMounted(() => {
      console.log('state', store.state)
      // console.log('user', userId.value.id)
    })

    return {
      showDialog,
      hideDialog,
      initialValues,
      schemaValidation,
      onSubmit
    }
  }
})

