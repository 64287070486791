
/* eslint-disable */
import {
  ref,
  toRefs,
  watch
} from 'vue'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

export default {
  components: {
    InputText,
    IconField,
    InputIcon
  },
  props: ['icon', 'label', 'modelValue', 'type', 'placeholder', 'canClick', 'inputBackground', 'autofocus', 'disabled'],
  emits: ['update:modelValue', 'submitInput'],
  setup(props: any, {
    emit
  }: any) {
    const icon = ref(props.icon)
    const label = ref(props.label)
    const {
      modelValue,
      canClick,
      disabled
    } = toRefs(props)
    const input = ref((modelValue) ? modelValue.value : '')
    const type = ref(props.type)
    const placeholder = ref(props.placeholder)
    const inputBackground = ref(props.inputBackground)
    const autofocus = ref(props.autofocus)

    watch(input, (newValue) => {
      emit('update:modelValue', newValue)
    })

    const onClick = () => {
      if (canClick) {
        emit('submitInput')
      }
    }
    const reset = () => {
      input.value = ''
    }

    return {
      icon,
      label,
      input,
      type,
      placeholder,
      canClick,
      onClick,
      reset,
      inputBackground,
      autofocus,
      disabled
    }
  }
}
