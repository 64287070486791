/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import {
  Remote,
  remoteEnum
} from '@/data/remote/remote'
import Repository from '@/data/repository'

class ApiUseCase {
  get(path: string, params: string = '', headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.get, `${path}${params}`, null, headers), null).getResult(false)
  }

  postOrPut(path: any, id: any, data: any = null, param: any = '', headers = {}): Promise<Response> {
    if (id) {
      return new Repository(new Remote(remoteEnum.put, `${path}/${id}${param}`, data, headers), null).getResult(false)
    }
    return new Repository(new Remote(remoteEnum.post, `${path}`, data, headers), null).getResult(false)
  }

  patch(path: any, id: any, data: any, headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.patch, `${path}/${id}`, data, headers), null).getResult(false)
  }

  delete(path: any, id: any, param: any = '', headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.delete, `${path}/${id}${param}`, null, headers), null).getResult(false)
  }

  getUser(path: any, data: any, headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.post, `${path}`, data, headers), null).getResult(false)
  }

  put(path: string, data: any, headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.put, `${path}`, data, headers), null).getResult(false)
  }

  putWithNoId(path: any, data: any, headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.put, `${path}`, data, headers), null).getResult(false)
  }

  postWithNoId(path: any, data: any = null, headers = {}): Promise<Response> {
    return new Repository(new Remote(remoteEnum.post, `${path}`, data, headers), null).getResult(false)
  }
}

const apiUseCase = new ApiUseCase()

export default apiUseCase
