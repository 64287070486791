<template>
    <!-- eslint-disable max-len -->
    <svg 
        width="32" 
        height="32" 
        viewBox="0 0 32 32" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.8058 12.3052H11.0361" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M14.0006 17.8574H11.0361" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M19.0023 28.3332H4.96191V3.6665H26.3969V28.3332" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
    </svg>
  </template>
  
  <script>
    export default {
        props: ['currentFill']
    }
  </script>