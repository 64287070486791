import CryptoJS from 'crypto-js'
import store from '@/store'
import base64url from 'base64url'

class EncryptDecriptMethods {
  private store = store

  private password = '3zTvzr3p67VC61jmV54rIYu1545x4TlY'

  private modules = 'appActiveUser'

  encrypt = (val: any) => {
    const encryptData = CryptoJS.AES.encrypt(JSON.stringify(val), this.password).toString()
    const fromBase64 = base64url.fromBase64(encryptData)
    return fromBase64
  }

  // decrypt = (val: any) => {
  //   const toBase64 = base64url.toBase64(val.toString())
  //   const bytes = CryptoJS.AES.decrypt(toBase64, this.password)
  //   const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  //   return decryptedData
  // }
}

const encryptDecriptMethods = new EncryptDecriptMethods()

export {
  encryptDecriptMethods
}
