const statusStyles: Record<string, { backgroundColor: string; label: string }> = {
  Pending: { backgroundColor: 'bg-yellow-500', label: 'Menunggu' },
  Processed: { backgroundColor: 'bg-blue-500', label: 'Diproses' },
  Success: { backgroundColor: 'bg-green-500', label: 'Berhasil' },
  Expired: { backgroundColor: 'bg-red-500', label: 'Kadaluarsa' },
  Failure: { backgroundColor: 'bg-gray-500', label: 'Gagal' },
}

export const getStatusStyle = (status: string) => {
  const style = statusStyles[status]
  return style ? style : { backgroundColor: 'bg-gray-300', label: status }
}