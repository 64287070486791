/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import { IAuthState } from './model'
import { IRootState } from '@/store/model'

const state: IAuthState = {
  accessToken: null,
  expTime: null,
  refreshToken: null,
  type: null,
  userinfo: null,
  companyId: null
}

const mutations: MutationTree<IAuthState> = {
  SET_EXPDATE_AUTH: (state, data) => {
    state.expTime = data
  },
  SET_TOKEN_AUTH: (state, data) => {
    state.accessToken = data.accessToken
    state.expTime = data.expiresIn
    state.refreshToken = data.refreshToken
    state.type = data.tokenType
  },
  SET_USERINFO: (state, data) => {
    state.userinfo = data
    if (data.additionalData?.companyId) {
      state.companyId = data.additionalData.companyId
      localStorage.setItem('ci', data.additionalData.companyId)
    }
  },
  FLUSH_AUTH: (state) => {
    state.accessToken = null
    state.expTime = null
    state.refreshToken = null
    state.type = null
    state.userinfo = null
  }
}

const actions: ActionTree<IAuthState, IRootState> = {
  setExpTimeAuth: ({ commit }, data: any) => {
    commit('SET_EXPDATE_AUTH', data)
  },
  setTokenAuth: ({ commit }, data: any) => {
    commit('SET_TOKEN_AUTH', data)
    // const date = moment()
    const date = moment().add(data.expiresIn, 'seconds')
    localStorage.setItem('ut', data.accessToken)
    localStorage.setItem('rt', data.refreshToken)
    localStorage.setItem('ei', date.toString())
  },
  flushAuth: ({ commit }) => {
    commit('FLUSH_AUTH')
  },
  fetchUserinfo: async ({ commit }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { error, result } = await apiUseCase.get('/openapi/v1/Identity/Info')
      if (!error) {
        commit('SET_USERINFO', result)
      }
    } catch (error) {
      throw error
    }
  }
}

const getters: GetterTree<IAuthState, IRootState> = {
  getAuthExpdate: (state) => state.expTime,
  getAuthToken: (state) => state.accessToken,
  getAuthType: (state) => state.type,
  getAuthRefreshToken: (state) => state.refreshToken,
  getAuthUserinfo: (state) => state.userinfo
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
