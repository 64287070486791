import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "block font-medium mb-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_3 = {
  key: 0,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroupAddon = _resolveComponent("InputGroupAddon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_InputGroup, null, {
      default: _withCtx(() => [
        _createVNode(_component_InputGroupAddon, {
          class: _normalizeClass(_ctx.addonClass)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.addon), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_InputText, {
          modelValue: _ctx.stringValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stringValue) = $event)),
          placeholder: _ctx.placeholder,
          class: _normalizeClass(['w-full border rounded-md p-2', { 'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path) }]),
          type: _ctx.inputType
        }, null, 8, ["modelValue", "placeholder", "class", "type"])
      ]),
      _: 1
    }),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path, _ctx.label)), 1))
      : _createCommentVNode("", true)
  ]))
}