<template>
  <div class="flex items-center">
    <div class="flex items-center" v-for="(item, index) in list" :key="index">
      <div v-if="item.icon !== null" v-html="item.icon" class="mr-2"></div>
      <p :class="classHover(item)" @click="redirect(item)">
        {{ item.title }}
      </p>
      <div v-if="index < list.length - 1" class="mx-1" v-html="$icon.svg.arrowRightwardFillEva"></div>
    </div>
  </div>
</template>
<script>
import environment from '@/configs/environtment'
import { computed } from 'vue'

export default {
  name: 'BreadcrumbMain',
  props: ['list'],
  setup() {
    const isProduction = computed(() => environment.getMode() === 'production')
    return {
      isProduction
    }
  },
  methods: {
    redirect(item) {
      if (item.routeName !== null && item.routeName !== this.$route.name) {
        this.$router.push({
          name: item.routeName,
          params: item.params ? { id: item.params } : null,
          query: item.query ?? ''
        })
        this.$store.dispatch('ui/setBreadcrumbs', [])
      }
    },
    classHover(item) {
      let classes = ''
      if (this.$route.name === item.routeName) {
        classes += `font-bold ${!this.isProduction ? 'text-secondary-500' : 'text-primary-500'}`
      }

      if (item.routeName !== null) {
        classes += ` cursor-pointer ${!this.isProduction ? '.hover-color-secondary-500:hover' : '.hover-color-primary-500:hover'}`
      }
      return classes
    }
  }
}
</script>